import moment from 'moment';
import React, { FunctionComponent, useContext, useEffect, useMemo, useState } from 'react';

export interface NotificationContextType {
  newFeedbackNotification: (notification: NotificationInput) => void;
  newContextNotification: (notification: NotificationInput) => void;
  removeFeedbackNotification: (id: string) => void;
  removeContextNotification: (id: string) => void;
  contextualQueue: NotificationInput[];
  feedbackQueue: NotificationInput[];
}

const NotificationContext = React.createContext<NotificationContextType | null>(null);

export interface NotificationInput {
  level: 'error' | 'warning' | 'success' | 'info';
  message: string;
  type?: 'contextual' | 'feedback';
  id?: string;
  timestamp?: Date | string;
  title?: string;
}

export const NotificationContainer: FunctionComponent = () => {
  const { contextualQueue, feedbackQueue, removeContextNotification, removeFeedbackNotification } = useNotification();

  const NotificationCard = ({ data }: { data: NotificationInput }): JSX.Element => {
    const { id, timestamp, message, type, level } = data;
    let { title } = data;
    if (!title) {
      if (type === 'feedback') {
        switch (level.toLowerCase()) {
          case 'error':
            title = 'Error';
            break;
          case 'warning':
            title = 'Advertencia';
            break;
          case 'success':
            title = 'Éxito';
            break;
          case 'info':
            title = 'Información';
            break;
          default:
            title = 'Éxito';
            break;
        }
      }
    }

    const removeThisNotification = (_id: string): void => {
      type === 'contextual' ? removeContextNotification(_id) : removeFeedbackNotification(_id);
    };

    return (
      <div
        className={`notification-card ${type} ${level.toLowerCase() || 'info'}`}
        onClick={() => removeThisNotification(id ?? '')}
        data-id={id}
      >
        <div className='card-content'>
          {timestamp && <small className='timestamp'>{moment(timestamp).fromNow()}</small>}
          {type === 'feedback' && <span className='times'>&times;</span>}
          <p className='title'>{title}</p>
          <small className={type === 'feedback' ? 'feedback-message' : 'message'}>{message}</small>
        </div>

        <style jsx>{`
          .notification-card {
            position: relative;
            width: 300px;
            border-radius: 10px;
            margin: 20px;
            cursor: pointer;
            box-shadow: 1px 3px 4px rgba(0, 0, 0, 0.2);

            animation-duration: 0.8s;
            animation-fill-mode: forwards;
             {
              /* animation-name: like; */
            }
          }

          @keyframes like {
            0% {
              transform: scale(0.01);
            }
            100% {
              transform: scale(1);
            }
          }
          .notification-card.contextual {
            padding-left: 15px;
            background-color: var(--primary-color);
          }
          .notification-card.error {
            background-color: #b72930;
          }
          .notification-card.warning {
            background-color: #ffb04c;
          }
          .notification-card.success {
            background-color: #03ac13;
          }
          .notification-card.info {
            background-color: #17a2b8;
          }

          .contextual .card-content {
            background-color: #fff;
          }
          .card-content {
            padding: 8px 15px;
            width: 100%;
            border-top-right-radius: 10px;
            border-bottom-right-radius: 10px;
          }
          .feedback .card-content * {
            color: #fff;
          }
          .timestamp,
          .times {
            position: absolute;
            right: 15px;
          }
          .times {
            font-size: 24px;
            top: 0;
          }
          .timestamp {
            top: 13px;
            color: var(--gray600);
          }
          .title {
            color: var(--gray800);
            text-transform: uppercase;
            font-weight: 700;
            font-size: 14px;
            margin-top: 5px;
            margin-bottom: 5px;
          }
          .feedback-message {
            max-width: calc(100% - 15px);
            font-size: 14px;
            line-height: 150%;
            word-wrap: break-word;
            margin-bottom: 0;
            margin-top: 0;
          }
          .message {
            color: var(--gray600);

            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
          }

          @media screen and (max-width: 640px) {
            .notification-card {
              margin: 15px auto;
              width: 90vw;
            }
          }
        `}</style>
      </div>
    );
  };

  return (
    <div id='NotificationContainer'>
      <div className='contextual queue top-right'>
        {contextualQueue.map(data => (
          <NotificationCard key={data.id} data={data} />
        ))}
      </div>

      <div className='feedback queue bottom-right'>
        {feedbackQueue.map(data => (
          <NotificationCard key={data.id} data={data} />
        ))}
      </div>

      <style jsx>{`
        #NotificationContainer .queue {
          z-index: 999;
          position: fixed;
          right: 0;
        }

        #NotificationContainer .contextual.top-right {
          top: 0;
        }
        #NotificationContainer .feedback.bottom-right {
          bottom: 0;
        }

        @media screen and (max-width: 640px) {
          #NotificationContainer .queue {
            width: 100vw;
          }

          #NotificationContainer .contextual.top-right {
            top: 58px;
          }
        }
      `}</style>
    </div>
  );
};

export const NotificationProvider: FunctionComponent = props => {
  const [contextualQueue, setContextualQueue] = useState<NotificationInput[]>([]);
  const [feedbackQueue, setFeedbackQueue] = useState<NotificationInput[]>([]);
  const [newNotification, setNewNotification] = useState<NotificationInput | null>(null);

  const timer = 4000;

  useEffect(() => {
    if (newNotification !== null) {
      newNotification?.type === 'contextual'
        ? removeContextNotification(newNotification?.id ?? '')
        : removeFeedbackNotification(newNotification?.id ?? '');
    }
  }, [newNotification]);

  const newContextNotification = (newNotification: NotificationInput): void => {
    const id = `${new Date().getTime()}`;
    setContextualQueue([{ ...newNotification, type: 'contextual', id }, ...contextualQueue]);
    setTimeout(() => setNewNotification({ ...newNotification, id, type: 'contextual' }), timer);
  };

  const newFeedbackNotification = (newNotification: NotificationInput): void => {
    const id = `${new Date().getTime()}`;
    setFeedbackQueue([{ ...newNotification, type: 'feedback', id }, ...feedbackQueue]);
    setTimeout(() => setNewNotification({ ...newNotification, id, type: 'feedback' }), timer);
  };

  const removeContextNotification = (id: string): void => {
    setContextualQueue(contextualQueue.filter(item => item.id !== id));
  };

  const removeFeedbackNotification = (id: string): void => {
    setFeedbackQueue(feedbackQueue.filter(item => item.id !== id));
  };

  // Only change the document when contextualQueue or feedbackQueue change.
  const value: NotificationContextType = useMemo(() => {
    return {
      contextualQueue,
      feedbackQueue,
      newContextNotification,
      newFeedbackNotification,
      removeContextNotification,
      removeFeedbackNotification
    };
  }, [contextualQueue, feedbackQueue]);

  return <NotificationContext.Provider value={value} {...props} />;
};

export const useNotification = (): NotificationContextType => {
  const context = useContext(NotificationContext);
  if (!context) {
    throw new Error('useNotification must be inside of NotificationContext');
  }

  return context;
};
